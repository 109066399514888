function refresher(ajax_delay,domainId,keyId) {
    var ajax_delay = ajax_delay || 9000,
        requestParams = {},
        chartID;
    var recursive_mode = true;
    // prepare URL request params
    requestParams.refresher_version = 'v1.0'; // do not forget to change the version on refresher.php **
    requestParams.lang = domainId;
    requestParams.quotes_ids = $('#toolQuotesIds'+keyId).val();
    
    var cdnPath = 'http://gencdn.fxnewstoday.ae';
    var arrowUp = '/images/arrow_up.png';
    var arrowDown = '/images/arrow_down.png';
    
    $.ajax({
        url: '/requestRefresher',
        type: "GET",
        data: requestParams,
        dataType: "json",
        success:
            function(data) {
    	
    			//$('.celllast').removeClass('BgGreenUp').removeClass('BgRedDown');
				
    			$.each(data, function(quoteId, quoteData) {
    				
    	 			var cellColorCss = '';
    	 			var arrowRemoveCss = '';
    	 			var arrowCss = '';
    	 			var applyChange = false;
    	 			var cellBgRemoveCss = '';
    	 			
    	 			currentLast =  parseFloat($('#last_'+quoteId).text());
    	 			
    	 			//if(quoteData['last_step_direction']=='Up') {
    	 			if(quoteData['last']!=currentLast) {
    	 				applyChange = true;

    	 				if(quoteData['last']>currentLast) {
        	 				cellBgCss = 'BgGreen';
        	 				cellBgRemoveCss = 'BgRed';
        	 			} else if(quoteData['last']<currentLast) {
        	 				cellBgCss = 'BgRed';
        	 				cellBgRemoveCss  = 'BgGreen';
        	 			}
    	 				
	    	 			if(quoteData['change_direction']=='Up') {
	    	 				arrowCss = 'up';
	    	 				arrowRemoveCss = 'down';
	    	 			} else if(quoteData['change_direction']=='Down') {
	    	 				arrowCss = 'down';
	    	 				arrowRemoveCss = 'up';
	    	 			}
    	 			} else {
    	 				applyChange = false;
    	 				cellBgCss = '';
    	 				cellBgRemoveCss = '';
    	 			}
    	 			//
    	 			if(applyChange) {
    	 				//$('#change_rate_'+quoteId).removeClass(cellBgRemoveCss).addClass(cellBgCss);
    	 				$('#last_'+quoteId).removeClass(cellBgRemoveCss).addClass(cellBgCss);
    	 				$('#last_'+quoteId).addClass(cellColorCss);
    	 				$('#change_rate_'+quoteId).addClass(arrowCss).removeClass(arrowRemoveCss);
    	 				$('#change_percent_'+quoteId).addClass(arrowCss).removeClass(arrowRemoveCss);
    	 				$('#arrow_'+quoteId).removeClass(arrowRemoveCss).addClass(arrowCss);
    	 				
    	 				$('#last_'+quoteId).html(quoteData['last']);
    	 				$('#high_'+quoteId).html(quoteData['high']);
    	 				$('#low_'+quoteId).html(quoteData['low']);
    	 				$('#change_rate_'+quoteId).html(quoteData['change_rate']);
	    	 			$('#change_percent_'+quoteId).html(quoteData['change_percent']);
    	 			} else {
    	 				//$('#change_rate_'+quoteId).removeClass('BgRed').removeClass('BgGreen');
    	 				$('#last_'+quoteId).removeClass('BgRed').removeClass('BgGreen');
    	 			}
    	 		});
    	 		if (recursive_mode) {
		             setTimeout( function() {
		                 refresher(9000,domainId,keyId);
		
		             }, 9000);
    	 		}               
            },
        error:
            function(XMLHttpRequest, textStatus, errorThrown)
            {
                setTimeout( function() {
                    refresher(9000,domainId,keyId);

                }, 9000);
            }
    });
}