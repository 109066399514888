var loading_container = '<div class="site-float" style="width:200px;height:180px;"><img src="/images/ajax-loading.gif" style="margin:30px 138px 0px 0px;" /></div>';

$(function() {
	  
});

function changeTabTool(tabName,keyId,quotesIds,cssfile,showMenuIndices,chartWidth,colHigh,colLow) {
	$('#'+keyId).html(loading_container);

	$('#Tabs'+keyId+' div').each(function() {
		if($(this).hasClass('selected')) {
			$(this).removeClass('selected');
		}
	});
	$('#TabContainer'+tabName).addClass('selected');

	$.get('/requestByMenuTab', {  
						 tabName:tabName, 
						 keyId:keyId, 
						 quotesIds:quotesIds, 
						 cssfile:cssfile,
						 showMenuIndices:showMenuIndices,
						 chartWidth:chartWidth,
						 colHigh:colHigh,
						 colLow:colLow
					   }, function(data) {
		$('#'+keyId).html(data);
	});
}
function rowChangeHoverDelayed(obj,keyId,tableId,quote_id,chartWidth) {
	if( $('#currentQuoteId'+keyId).val() != quote_id) {
		setTimeout(function(){rowChangeHover(obj,keyId,tableId,quote_id,chartWidth)}, 300);
	}
}
function rowChangeHover(obj,keyId,tableId,quote_id,chartWidth) {
	
	$('#'+tableId+' div').each(function (){ $(this).removeClass('selected'); });
	$(obj).addClass('selected');
	
	$('#chartContainer').html(loading_container);
	
	$.get('/requestChangeChart', { 
							quote_id:quote_id,
							chartWidth:chartWidth 
						  }, function(data) {
		$('#chartContainer').html(data);
	});
	
	$('#currentQuoteId'+keyId).val(quote_id);
	
}
function isMsie() {
	if (navigator.userAgent.search("MSIE") >= 0 || navigator.userAgent.search(".NET") >= 0) {
		return true;
	}
	return false;
}